import * as React from 'react';
import { createPortal } from "react-dom";
import { i18NHelperContext } from "@inwink/i18n/reactcontext";
import type { VisualTheme } from "@inwink/entities/visualtheme";
import type { Entities } from "@inwink/entities/entities";
import type { States } from "@@services/services";
import { wrapReduxStore, IInwinkStore } from "@@store/index";
import { eventUserActionsModule } from "@@routes/appmodules";
import { UrlServiceContext } from "../urlstatecontext";
import { ToastContext } from "./toastContext";
import { addToast } from './addtoast';

const ToastItems = React.lazy(() => import("./toastitems"));

export interface IWithToastProviderProps {
    event?: States.IEventState;
    community?: States.ICommunityState;
    location?: States.ILocation;
    isRootWebsite?: boolean;
    history?: any;
    theme?: VisualTheme.IBlocTheme;
}

const defaultOptions : Partial<IGlobalToastOptions> = {
    position: 'top-right',
    autoDismissTimeout: 5000,
    disabledAutoDismiss: false,
    reverseList: true,
    showId: false
};

interface IToastProviderProps extends IWithToastProviderProps {
    children: React.ReactNode;
    store?: IInwinkStore;
    urlservice: States.IAppUrlContext;
    i18nHelper: Entities.i18nHelper;
}
interface IToastProviderState {
    toasts: IToast[];
    hasItems: boolean;
    toastcontext: {
        add: IAddToastCallback;
        remove: IRemoveToastCallback;
    };
}

class ToastProviderComponent extends React.Component<IToastProviderProps, IToastProviderState> {
    toastRootNode: HTMLDivElement;

    constructor(props: IToastProviderProps) {
        super(props);
        let toastRootNode = document.getElementById("toast-root") as HTMLDivElement;
        if (!toastRootNode) {
            const nextToastRootNode = document.createElement('DIV') as HTMLDivElement;
            nextToastRootNode.id = 'toast-root';
            document.body.appendChild(nextToastRootNode);
            toastRootNode = nextToastRootNode;
        }
        this.toastRootNode = toastRootNode;

        this.state = {
            toasts: [],
            hasItems: false,
            toastcontext: {
                add: this.add,
                remove: this.remove
            }
        };
        window.inwink.toast = this.state.toastcontext;
    }

    remove: IRemoveToastCallback = (id: string) => {
        this.setState((prevstate) => {
            return Object.assign({}, prevstate, {
                toasts: prevstate.toasts.filter((t) => t.id !== id)
            });
        });
    };

    add: IAddToastCallback = (
        arg: IAddToastArgument,
        type?: IToastType,
        optionsParam?
    ) => {
        addToast(
            arg,
            optionsParam,
            type,
            defaultOptions,
            this.props.store,
            this.props.location,
            this.props.history,
            this.props.isRootWebsite,
            this.props.urlservice,
            this.props.i18nHelper,
            this.props,
            this.remove
        ).then((toast) => {
            if (toast) {
                if (!this.state.hasItems) {
                    this.setState({ hasItems: true });
                }
                this.setState((prevstate) => {
                    let toasts = prevstate.toasts;
                    if (!hasExistingToast(toast.toast, toasts)) {
                        if (defaultOptions?.reverseList) {
                            toasts = [toast, ...toasts];
                        } else {
                            toasts = [...toasts, toast];
                        }
                    }
                    return Object.assign({}, prevstate, {
                        toasts: toasts
                    });
                });
            }
        });
    };

    onClick = (toast: IToast, dismissNotif = false) => {
        if (toast.type === 'realTime') {
            // if (props?.userNotificationsActions?.dismissNotifications) {
            //     // props.userNotificationsActions.dismissNotifications([id]);
            // }
        } else if (toast.options?.onClick) {
            toast.options.onClick(toast.id);
        }

        if (dismissNotif) {
            const store = this.props.store;
            const state: States.IAppState = store.getState();
            if (state.user?.currentUser?.detail && state.event?.eventid) {
                eventUserActionsModule().then((mod) => {
                    mod.userNotificationsActions.dismissNotifications([toast.id])(store.dispatch, store.getState);
                });
            }
        }
    };

    render() {
        return <ToastContext.Provider value={this.state.toastcontext}>
            {this.props.children}
            {
                this.state.hasItems && (
                    createPortal(
                        <React.Suspense fallback={<></>}>
                            <ToastItems
                                toasts={this.state.toasts}
                                remove={this.remove}
                                event={this.props.event}
                                community={this.props.community}
                                onClick={this.onClick}
                                options={defaultOptions}
                                theme={this.props.theme}
                            />
                        </React.Suspense>,
                        this.toastRootNode
                    )
                )
            }
        </ToastContext.Provider>;
    }
}

const ToastProvider = wrapReduxStore(ToastProviderComponent);

export function withToastProvider(baseComponent) {
    if (__SERVERSIDE__) {
        return baseComponent;
    }

    return function WithToastProvider(props: IWithToastProviderProps) {
        return <i18NHelperContext.Consumer>
            {(i18nHelper) => <UrlServiceContext.Consumer>
                {(urlservice) => <ToastProvider
                    {...props}
                    urlservice={urlservice}
                    i18nHelper={i18nHelper}
                >
                    {React.createElement(baseComponent, props)}
                </ToastProvider>}
            </UrlServiceContext.Consumer>}
        </i18NHelperContext.Consumer>;
    };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
// export function withToastProviderOld(Component) {
//     if (__SERVERSIDE__) {
//         return Component;
//     }
//     // eslint-disable-next-line react/display-name
//     return (props: IWithToastProviderProps) => {
//         const { user, event, history, isRootWebsite, location } = props;
//         const urlservice: States.IAppUrlContext = React.useContext(UrlServiceContext);
//         const i18nHelper: Entities.i18nHelper = React.useContext(i18NHelperContext);
//         const [toastRootNode, setToastRootNode] = React.useState(null as any);
//         const [hasItems, setHasItems] = React.useState(false);
//         const [toasts, setToasts] = React.useState<IToast[]>([]);
//         // const [options, setOptions] = React.useState<IGlobalToastOptions>(defaultOptions as any);
//         const store = useStore();

//         // if (typeof document === 'undefined') {
//         //     return null;
//         // }

//         const remove: IRemoveToastCallback = (id: string) => {
//             setToasts(toasts.filter((t) => t.id !== id));
//         };

//         const add: IAddToastCallback = (
//             arg: IAddToastArgument,
//             type?: IToastType,
//             optionsParam?
//         ) => {
//             import('./addtoast').then((mod) => {
//                 mod.addToast(
//                     arg,
//                     optionsParam,
//                     type,
//                     defaultOptions,
//                     store,
//                     location,
//                     history,
//                     isRootWebsite,
//                     urlservice,
//                     i18nHelper,
//                     props,
//                     remove
//                 ).then((toast) => {
//                     if (toast) {
//                         if (!hasItems) {
//                             setHasItems(true);
//                         }
//                         if (defaultOptions?.reverseList) {
//                             setToasts((prevToasts) => {
//                                 if (!hasExistingToast(toast.toast, prevToasts)) {
//                                     return [toast, ...prevToasts];
//                                 }
//                                 return prevToasts;
//                             });
//                         } else {
//                             setToasts((prevToasts) => {
//                                 if (!hasExistingToast(toast.toast, prevToasts)) {
//                                     return [...prevToasts, toast];
//                                 }
//                                 return prevToasts;
//                             });
//                         }
//                     }
//                 });
//             });
//         };

//         const onClick = (toast: IToast, dismissNotif = false) => {
//             if (toast.type === 'realTime') {
//                 // if (props?.userNotificationsActions?.dismissNotifications) {
//                 //     // props.userNotificationsActions.dismissNotifications([id]);
//                 // }
//             } else if (toast.options?.onClick) {
//                 toast.options.onClick(toast.id);
//             }

//             if (dismissNotif) {
//                 const state: States.IAppState = store.getState();
//                 if (state.user?.currentUser?.detail) {
//                     userActionsModule().then((mod) => {
//                         mod.userNotificationsActions.dismissNotifications([toast.id])(store.dispatch, store.getState);
//                     });
//                 }
//             }
//         };

//         React.useEffect(() => {
//             if (typeof window !== 'undefined') {
//                 window.inwink.toast = { add, remove };
//             }
//         }, [user]);

//         React.useEffect(() => {
//             if (!toastRootNode && typeof document !== 'undefined') {
//                 const nextToastRootNode = document.createElement('DIV');
//                 nextToastRootNode.id = 'toast-root';
//                 document.body.appendChild(nextToastRootNode);

//                 setToastRootNode(nextToastRootNode);
//             }
//         }, []);

//         const providerValue = React.useMemo(() => { return { add, remove }; }, [toasts]);

//         return (
//             <ToastContext.Provider value={providerValue}>
//                 <Component {...props} />

//                 {
//                     toastRootNode && hasItems && (
//                         createPortal(
//                             <React.Suspense fallback={<></>}>
//                                 <ToastItems
//                                     toasts={toasts}
//                                     remove={remove}
//                                     event={event}
//                                     onClick={onClick}
//                                     options={defaultOptions}
//                                     theme={props.theme}
//                                 />
//                             </React.Suspense>,
//                             toastRootNode
//                         )
//                     )
//                 }
//             </ToastContext.Provider>
//         );
//     };
// }

export default withToastProvider;

function hasExistingToast(arg: IAddToastArgument, toasts: IToast[]) {
    if (arg.usernotification) {
        const ns = arg.usernotification;
        const alreadyHasExisting = toasts.some((t) => {
            const n = t.toast?.usernotification;
            if (!n) {
                return false;
            }

            return n.relatedToKind === ns.relatedToKind
                && n.relatedToId === ns.relatedToId
                && n.notificationType === ns.notificationType
                && n.message === ns.message;
        });

        return alreadyHasExisting;
    }

    return false;
}
